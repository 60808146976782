import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class PagesService {
  pages = [
    /* {
      id: '#',
      name: 'reddit',
      cover: '/assets/images/origins/reddit.png',
    },
    {
      id: '#',
      name: 'Grasscity',
      cover: '/assets/images/origins/grasscity.png',
    },
    {
      id: '#',
      name: 'CannabisCafe.net',
      cover: '/assets/images/origins/cannabiscafe.png',
    },
    {
      id: '#',
      name: 'The Cannabis Web',
      cover: '/assets/images/origins/TCW.png',
    }*/
    /* {
      id: 'page:cannvest',
      name: 'Cannvest',
      cover: '/assets/images/origins/cannvest.png',
      logo: '/assets/images/origins/cannvest-circle.png',
    },
    {
      id: 'page:colombiacom',
      name: 'Colombia.com',
      cover: '/assets/images/origins/colombiacom.png',
      logo: '/assets/images/origins/colombiacom-circle.png',
    },
    {
      id: 'page:elpais',
      name: 'El País',
      cover: '/assets/images/origins/elpais.png',
      logo: '/assets/images/origins/elpais-circle.png',
    },
    {
      id: 'page:enter',
      name: 'Enter.co',
      cover: '/assets/images/origins/enter.png',
      logo: '/assets/images/origins/enter-circle.png',
    },
    {
      id: 'page:noticiasrcn',
      name: 'Noticias RCN',
      cover: '/assets/images/origins/noticiasrcn.png',
      logo: '/assets/images/origins/noticiasrcn-circle.png',
    },
    {
      id: 'page:razonpublica',
      name: 'Razón Pública',
      cover: '/assets/images/origins/razonpublica.png',
      logo: '/assets/images/origins/razonpublica-circle.png',
    }, */
    {
      id: 'page:diarioadn',
      name: 'ADN',
      cover: '/assets/images/origins/adn.png',
      logo: '/assets/images/origins/adn-circle.png',
    },
    {
      id: 'page:alertabogota',
      name: 'Alerta Bogotá',
      cover: '/assets/images/origins/alertabogota.png',
      logo: '/assets/images/origins/alertabogota-circle.png',
    },
    {
      id: 'page:elconfidencial',
      name: 'El Confidencial',
      cover: '/assets/images/origins/elconfidencial.png',
      logo: '/assets/images/origins/elconfidencial-circle.png',
    },
    {
      id: 'page:elespectador',
      name: 'El Espectador',
      cover: '/assets/images/origins/elespectador.png',
      logo: '/assets/images/origins/elespectador-circle.png',
    },
    {
      id: 'page:elportafolio',
      name: 'Portafolio',
      cover: '/assets/images/origins/elportafolio.png',
      logo: '/assets/images/origins/elportafolio-circle.png',
    },
    {
      id: 'page:eltiempo',
      name: 'El Tiempo',
      cover: '/assets/images/origins/eltiempo.png',
      logo: '/assets/images/origins/eltiempo-circle.png',
    },
    {
      id: 'page:eluniversal',
      name: 'El Universal',
      cover: '/assets/images/origins/eluniversal.png',
      logo: '/assets/images/origins/eluniversal-circle.png',
    },
    {
      id: 'page:kienyke',
      name: 'KienYKe.com',
      cover: '/assets/images/origins/kienyke.png',
      logo: '/assets/images/origins/kienyke-circle.png',
    },
    {
      id: 'page:las2orillas',
      name: 'Las 2 Orillas',
      cover: '/assets/images/origins/las2orillas.png',
      logo: '/assets/images/origins/las2orillas-circle.png',
    },
    {
      id: 'page:minuto30',
      name: 'Minuto30.com',
      cover: '/assets/images/origins/minuto30.png',
      logo: '/assets/images/origins/minuto30-circle.png',
    },
    {
      id: 'page:noticiascaracoltv',
      name: 'Noticias Caracol',
      cover: '/assets/images/origins/noticiascaracol.png',
      logo: '/assets/images/origins/noticiascaracol-circle.png',
    },
    {
      id: 'page:ntn24',
      name: 'NTN 24',
      cover: '/assets/images/origins/ntn24.png',
      logo: '/assets/images/origins/ntn24-circle.png',
    },
    {
      id: 'page:pulzo',
      name: 'Pulzo',
      cover: '/assets/images/origins/pulzo.png',
      logo: '/assets/images/origins/pulzo-circle.png',
    },
    {
      id: 'page:vanguardia',
      name: 'Vanguardia',
      cover: '/assets/images/origins/vanguardia.png',
      logo: '/assets/images/origins/vanguardia-circle.png',
    },
    {
      id: 'page:lamarihuana',
      name: 'La Marihuana.com',
      cover: '/assets/images/origins/lamarihuana.png',
      logo: '/assets/images/origins/lamarihuana-circle.png',
    },
    {
      id: 'page:cannabisn24',
      name: 'Cannabisn24',
      cover: '/assets/images/origins/cannabis24.png',
      logo: '/assets/images/origins/cannabis24-circle.png',
    },
    {
      id: 'page:natuera',
      name: 'Natuera',
      cover: '/assets/images/origins/natuera-org.png',
      logo: '/assets/images/origins/natuera-circle.png',
    },
    {
      id: 'page:canamo',
      name: 'Cañamo',
      cover: '/assets/images/origins/canamo.png',
      logo: '/assets/images/origins/canamo-circle.png',
    },
    /* {
      id: 'page:semana',
      name: 'Semana',
      cover: '/assets/images/origins/semana.png',
      logo: '/assets/images/origins/semana-circle.png',
    },
    {
      id: 'page:larepublica',
      name: 'La República',
      cover: '/assets/images/origins/larepublica.png',
      logo: '/assets/images/origins/larepublica-circle.png',
    }, */
    {
      id: 'page:cannabislandia',
      name: 'Cannabislandia',
      cover: '/assets/images/origins/cannabislandia.png',
      logo: '/assets/images/origins/cannabislandia-circle.png',
    },
    {
      id: 'page:zamnesia',
      name: 'Zamnesia',
      cover: '/assets/images/origins/zamnesia.png',
      logo: '/assets/images/origins/zamnesia-circle.png',
    },
    {
      id: 'page:thcfarmer',
      name: 'THC Farmer',
      cover: '/assets/images/origins/thcfarmer.png',
      logo: '/assets/images/origins/thcfarmer-circle.png',
    },
    {
      id: 'page:oedcm',
      name: 'OEDCM',
      cover: '/assets/images/origins/oedcm.png',
      logo: '/assets/images/origins/oedcm-circle.png',
    },
  ];

  getPages() {
    return of(this.pages);
  }

  getPage(page_id: string) {
    return this.pages.find((item) => item.id == page_id);
  }
}
