import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'noticias',
    loadChildren: () =>
      import('./pages/articles/articles.module').then((m) => m.ArticlesModule),
  },
  {
    path: 'fuentes',
    loadChildren: () =>
      import('./pages/origin/origin.module').then((m) => m.OriginModule),
  },
  {
    path: 'tienda',
    loadChildren: () =>
      import('./pages/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./pages/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'terms-and-privacy',
    loadChildren: () =>
      import('./pages/terms-and-privacy/terms-and-privacy.module').then(
        (m) => m.TermsAndPrivacyModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'noticias',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
