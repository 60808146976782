import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './@shared/shared.module';
import { SwiperModule } from 'swiper/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ArticleService } from './@core/services/article.service';
import { PagesService } from './@core/services/pages.service';

import { registerLocaleData } from '@angular/common';
import localeCo from '@angular/common/locales/es-CO';
import localeCoExtra from '@angular/common/locales/extra/es-CO';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

registerLocaleData(localeCo, 'es-CO', localeCoExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SharedModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SwiperModule,
    MaterialModule
  ],
  providers: [
    ArticleService,
    PagesService,
    { provide: LOCALE_ID, useValue: 'es-CO' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
