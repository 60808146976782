import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/@core/models/menu.model';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public active: boolean = false;
  public sections: Menu = {
    items: [
      { text: 'Tienda', url: 'http://tienda.elgreenpost.com/', img: '' },
      /* { text: 'Inicio', url: '/noticias', img: '' }, */
      { text: 'Salud', url: '/noticias?channels=salud', img: '' },
      // { text: 'Política', url: '/noticias?channels=politica', img: '' },
      { text: 'Cultivo', url: '/noticias?channels=cultivo', img: '' },
      { text: 'Recreativa', url: '/noticias?channels=recreativa', img: '' },
      { text: 'Gastronomía', url: '/noticias?channels=gastronomia', img: '' },
      { text: 'Fuentes', url: '/fuentes', img: '' },
      { text: 'Blog', url: 'https://tienda.elgreenpost.com/blog', img: '' },
      {
        text: 'Contacto',
        url: 'http://tienda.elgreenpost.com/contacto',
        img: '',
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {}
}
