import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { parse, format } from 'date-fns';
import { debounceTime } from 'rxjs/operators';
import { PagesService } from 'src/app/@core/services/pages.service';
import { ChannelService } from 'src/app/@core/services/channel.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  query = new UntypedFormControl('', [
    Validators.minLength(4),
    Validators.required,
  ]);
  showFilterForm = false;
  pages = [];
  queryParams: any = {};
  keywords: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Output() sendQuery = new EventEmitter();
  @Output() sendFilter = new EventEmitter();
  @Input()
  set params(data: any) {
    if (data) {
      this.queryParams = data;
      this.query.setValue(data?.query || '');
      if (data.from && data.to) {
        this.form
          .get('range')
          .get('from')
          .setValue(parse(data.from, 'yyyy-MM-dd', new Date()));
        this.form
          .get('range')
          .get('to')
          .setValue(parse(data.to, 'yyyy-MM-dd', new Date()));
      }
      if (data.pages) {
        const pages = data.pages.split(',');
        this.form.get('pages').setValue(pages);
      }
      if (data.order) {
        this.form.get('order').setValue(data.order);
      }
      if (data.keywords) {
        this.keywords = data.keywords?.split(',');
      }
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pagesService: PagesService /* private authService: AuthService, */
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.pagesService.getPages().subscribe((pages) => {
      this.pages = pages;
    });
    /* const config: any = this.authService.getConfigSearchByUser();
    if (config) {
      this.showFilterForm = !this.showFilterForm;
      this.form.get('pages').setValue(config?.pages);
      this.keywords = config?.keywords;
    } */
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      query: [null, [Validators.minLength(4), Validators.required]],
      pages: null,
      order: 'DESC',
      keywords: null,
      range: this.formBuilder.group({
        from: null,
        to: null,
      }),
    });

    this.query.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.form.get('query').setValue(value);
    });
  }

  doSendQuery() {
    if (this.query.valid) {
      this.form.get('query').setValue(this.query.value);
      this.doSubmit();
    } else {
      this.query.markAsTouched();
    }
  }

  doSubmit() {
    if (this.form.valid) {
      const values = this.form.value;
      const params = {
        query: values.query?.toLowerCase(),
        pages: values.pages?.join(','),
        order: values.order,
        keywords: this.keywords?.join(','),
        from: this.formatDate(values?.range?.from),
        to: this.formatDate(values?.range?.to),
      };
      this.doSendFilter(params);
    } else {
      this.query.markAsTouched();
      this.form.markAllAsTouched();
    }
  }

  toggleShowFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  private formatDate(date: Date) {
    if (date) {
      return format(date, 'yyyy-MM-dd');
    }
    return null;
  }

  doSendFilter(params: any) {
    const queryParams: any = {};
    if (params.query) {
      queryParams.query = params.query;
    }
    if (params.pages) {
      queryParams.pages = params.pages;
    }
    if (params.keywords) {
      queryParams.keywords = params.keywords;
    }
    if (params.order) {
      queryParams.order = params.order;
    }
    if (params.from && params.to) {
      queryParams.from = params.from;
      queryParams.to = params.to;
    }
    this.sendQuery.emit(queryParams);
  }

  clean() {
    this.form.reset();
    this.keywords = [];
    this.sendQuery.emit({});
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const keyword = (value || '').trim();
    if (keyword && keyword.length >= 4) {
      this.keywords.push(keyword.toLowerCase());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }
}
