<div class="footer" id="footer" #footer>
  <div class="container">
    <div class="grid">
      <div class="logo">
        <img src="/assets/images/logo-white.png" />
      </div>
      <div class="legend">
        El Green Post es un portal digital que crea opinión sobre la actualidad
        de cannabis en él mundo. Es el primer medio de comunicación que recopila
        noticias sobre cannabis del mundo, así mismo podrá encontrar productos y
        servicios asociados al Cannabis.
      </div>
      <!-- <div class="links-about"></div>
      <div class="links-our"></div>
      <div class="social-networks"></div> -->
    </div>
    <div class="reserved">
      © 2021 El Green Post. Todos los derechos reservados
    </div>
    <div class="reserved">
      Conoce nuestros
      <a routerLink="/terms-and-privacy/terms">Términos y condiciones</a> y
      <a routerLink="/terms-and-privacy/privacy">Políticas de privacidad</a>
    </div>
  </div>
</div>
