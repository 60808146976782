import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs/Observable";
import { Apollo } from "apollo-angular";

import gql from "graphql-tag";

import { RootQuery } from "../graphql";

@Injectable()
export class ArticleService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getAllArticles(offset: number, limit: number) {
    return this.apollo
      .query({
        query: gql`
          query($offset: Int!, $limit: Int!) {
            articles(offset: $offset, limit: $limit) {
              _id
              title
              url
              image
              date
              channels
              page_id
            }
          }
        `,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(
        map((response: any) => {
          return response.data.articles;
        })
      );
  }

  getArticlesByPage(pageId: string, offset: number, limit: number) {
    return this.apollo
      .query({
        query: gql`
          query($pageId: ID!, $offset: Int!, $limit: Int!) {
            articles: articlesByPage(
              id: $pageId
              offset: $offset
              limit: $limit
            ) {
              _id
              title
              url
              image
              date
              channels
              page_id
            }
          }
        `,
        variables: {
          pageId,
          offset,
          limit,
        },
      })
      .pipe(
        map((response: any) => {
          return response.data?.articles;
        })
      );
  }

  getArticlesByParams(params: {
    query?: string;
    offset: number;
    limit: number;
    pages?: string;
    channels?: string;
    order?: string;
    from?: string;
    to?: string;
  }) {
    return this.apollo
      .query({
        query: gql`
          query($params: SearchInput!) {
            articles: articlesByParams(params: $params) {
              _id
              title
              url
              image
              date
              channels
              page_id
            }
          }
        `,
        variables: {
          params: {
            ...params,
            pages: params.pages?.split(','),
            channels: params.channels?.split('.'),
          },
        },
      })
      .pipe(
        map((response: any) => {
          return response.data?.articles;
        })
      );
  }

  getArticle(id: string) {
    return this.apollo
      .query<RootQuery>({
        query: gql`
          query($id: ID!) {
            article(id: $id) {
              _id
              title
              page_id
              url
              image
              date
              texts
              description
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
