import { NgModule } from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';


@NgModule({
    imports: [
        MatFormFieldModule,
        MatNativeDateModule,
        MatChipsModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        ScrollingModule,
        MatSidenavModule
    ],
    exports: [
        MatFormFieldModule,
        MatNativeDateModule,
        MatChipsModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        ScrollingModule,
        MatSidenavModule
    ],
})
export class MaterialModule { }
