import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightKeywords',
})
export class HighlightKeywordsPipe implements PipeTransform {
  newValue: string = '';
  transform(value: any, args: string[]): any {
    const words = value.split(' ');

    if (!args || args.length === 0) {
      return value;
    }

    words.forEach((word) => {
      args.forEach((arg: string) => {
        const index = words.findIndex(
          (w: string) => w.toLowerCase() === arg.toLowerCase()
        );
        if (index === -1) {
          this.newValue = words.join(' ');
        }
        const regex = new RegExp(arg, 'gi');
        const match = word.match(regex);
        if (!match) {
          this.newValue = words.join(' ');
        } else {
          const replace = `<span class='highlight_keyword'>${match}</span>`;
          words[index] = replace;
          this.newValue = words.join(' ');
        }
      });
    });
    return this.newValue;
  }
}
