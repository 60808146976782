<div class="header" id="header" #header>
  <div class="container">
    <nav class="nav">
      <div class="nav__home">
        <a href="/" aria-label="Home">
          <img src="/assets/images/logo-white.png" />
        </a>
      </div>
      <div [ngClass]="{ active: active }" class="nav__sections">
        <a
          class="nav__link"
          *ngFor="let item of sections.items"
          [href]="item.url"
          >{{ item.text }}</a
        >
        <!-- <div class="subscription-buttons-mobile">
          <button>Subscribirse</button>
          <button><i class="fas fa-user"></i></button>
        </div> -->
      </div>
      <div
        [ngClass]="{ active: active }"
        (click)="active = !active"
        class="hamburger"
      >
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
      <!-- <div class="subscription-buttons-desk">
        <button>Subscribirse</button>
        <button><i class="fas fa-user"></i></button>
      </div> -->
    </nav>
  </div>
</div>
