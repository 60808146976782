<div class="search--container">
  <div class="search-field">
    <div>
      <div>
        <input
          placeholder="Buscar..."
          class="search-input"
          [formControl]="query"
          type="search"
          (keydown.enter)="doSendQuery()"
        />
        <button (click)="doSendQuery()" class="search-button">
          <i class="fas fa-search"></i></button
        ><button
          (click)="toggleShowFilterForm()"
          class="btn-success btn-filter"
        >
          Filtros
          <mat-icon
            *ngIf="showFilterForm"
            aria-hidden="false"
            aria-label="Example home icon"
            >expand_less</mat-icon
          >
          <mat-icon
            *ngIf="!showFilterForm"
            aria-hidden="false"
            aria-label="Example home icon"
            >expand_more</mat-icon
          >
        </button>
      </div>
      <mat-error
        *ngIf="
          query.invalid &&
          query.touched &&
          form.get('query').invalid &&
          query.hasError('minlength')
        "
        >Debe mínimo de 4 caracteres.</mat-error
      >
      <mat-error
        *ngIf="
          query.invalid &&
          query.touched &&
          form.get('query').invalid &&
          query.hasError('required')
        "
        >Es un campo obligatorio.</mat-error
      >
    </div>
  </div>
  <form
    [formGroup]="form"
    (ngSubmit)="doSubmit()"
    class="search-form"
    *ngIf="showFilterForm"
  >
    <div class="fa-row">
      <div class="fa-col fa-col-full">
        <mat-form-field appearance="fill">
          <mat-label>Fuentes</mat-label>
          <mat-select formControlName="pages" multiple>
            <mat-option *ngFor="let page of pages" [value]="page.id">{{
              page.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="fa-col fa-col-full">
        <mat-form-field appearance="fill">
          <mat-label>Rango de fecha</mat-label>
          <mat-date-range-input formGroupName="range" [rangePicker]="picker">
            <input matStartDate formControlName="from" placeholder="Desde" />
            <input matEndDate formControlName="to" placeholder="Hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="fa-col">
        <mat-button-toggle-group
          button-toggle-group
          formControlName="order"
          name="order"
          aria-label="Font Style"
        >
          <mat-button-toggle value="ASC">Más antiguo</mat-button-toggle>
          <mat-button-toggle value="DESC">Más reciente</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="fa-row">
      <div class="fa-col fa-col-full">
        <mat-form-field class="example-chip-list">
          <mat-label
            >Palabras clave, separa cada una por una , (coma)</mat-label
          >
          <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip
              *ngFor="let keyword of keywords; index as i"
              [selectable]="true"
              [removable]="true"
              (removed)="remove(i)"
            >
              {{ keyword }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Nueva palabra..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="fa-col btn-actions">
        <button mat-raised-button type="button" (click)="clean()">
          Limpiar
        </button>
        <button mat-raised-button type="submit" color="primary">Aplicar</button>
      </div>
    </div>
  </form>
</div>
